<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                v-if="isSaveButtonVisible"
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <app-button-delete v-if="isDeleteButtonVisible" @deleteRecord="deleteEdonProduct"></app-button-delete>
              <app-button-close route-name="edonProduct_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-12">
                    <input type="hidden" v-model="edonProduct.id">
                    <app-input
                      v-model="edonProduct.title"
                      @blur="$v.edonProduct.title.$touch()"
                      :error="$v.edonProduct.title.$error"
                      id="edonProduct_title"
                      :label="$t('edonProduct.title')"
                    >
                    </app-input>
                    <div class="form-group">
                      <label>
                        {{ $t('edonProduct.rubrics') }}
                      </label>
                      <app-multi-select
                        v-model="selectedRubrics"
                        :options="rubrics"
                        label="title"
                        track-by="id"
                        id="edonProduct_rubrics"
                      >
                      </app-multi-select>
                    </div>
                    <app-checkbox
                      v-model="edonProduct.mainProduct"
                      id="is_main_product"
                      :label="$t('edonProduct.mainProduct')"
                    >
                    </app-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import Input from '../../components/form/inputs/Input'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import EdonProductModel from '../../model/EdonProductModel'
import ButtonDelete from '../../components/shared/ButtonDelete'
import ButtonClose from '../../components/shared/ButtonClose'
import Checkbox from '../../components/form/Checkbox'
import MultiSelect from '../../components/form/select/MultiSelect'
import NotifyService from '../../services/NotifyService'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'EdonProductNewView',
  data () {
    return {
      view: 'new',
      dataLoaded: false,
      isLoading: false,
      edonProduct: this._.cloneDeep(EdonProductModel),
      selectedRubrics: [],
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.EDITOR_PERFORMANCE_EDON_PRODUCT_PERMISSIONS
    }
  },
  validations: {
    edonProduct: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appMultiSelect: MultiSelect,
    appCheckbox: Checkbox,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose
  },
  computed: {
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    isDeleteButtonVisible () {
      return this.edonProduct?.id && this.hasPermission(this.requiredPermissions.deleteButton)
    },
    isSaveButtonVisible () {
      return this.hasPermission(this.requiredPermissions.saveButton)
    },
    rubrics () {
      return this.$store.getters['rubric/rubricsWithSite']
    }
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    prepareRequest () {
      const productRequest = this._.cloneDeep(this.edonProduct)
      productRequest.rubrics = this.selectedRubrics.map(rubric => rubric.id)

      return productRequest
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.$store.dispatch('edonProduct/create', this.prepareRequest())
          .then(() => {
            if (this.$store.getters['edonProduct/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
              this.edonProduct = this.$store.getters['edonProduct/detail']
              this.$router.push('/edonProduct/' + this.edonProduct.id + '/edit')
            } else {
              NotifyService.setErrorMessage(this.$store.getters['edonProduct/error'])
            }
          })
          .catch(error => console.log(error))
      }
    },
    deleteEdonProduct () {
      this.$store.dispatch('edonProduct/deleteRecord', this.edonProduct)
        .then(() => {
          if (this.$store.getters['edonProduct/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/edonProduct')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['edonProduct/error'])
          }
        })
        .catch(error => console.log(error))
    },
    async loadRubrics () {
      if (this.$store.getters['rubric/all'].length === 0) {
        // usually the rubrics have been loaded from edon-product-list table
        //   but after user refresh (F5), the rubrics are not loaded at all, so we need to load them again
        await this.$store.dispatch('rubric/fetchAll')
      }
    }
  },
  async created () {
    if (this.view === 'new') {
      await this.loadRubrics()
    }
  }
}
</script>
